import React from "react";
import useStyles from "./fixedAspectRatioImage.styles";

export function FixedAspectRatioImage({
  src,
  ratioWidth = 1,
  ratioHeight = 1,
  boxClass = "",
  imgClass = ""
}) {
  const classes = useStyles();

  return (
    <div
      style={{
        '--ratio-width': ratioWidth.toString(),
        '--ratio-height': ratioHeight.toString()
      }}
      className={`${classes.aspectRatioBox} ${boxClass}`}
    >
      <img className={imgClass} src={src} />
    </div>
  );
}
