import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  aspectRatioBox: {
    position: 'relative',
    '--ratio-width': '1',
    '--ratio-height': '1',

    '& > :first-child': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0'
    },

    '&::before': {
      content: '""',
      display: 'block',
      paddingBottom: 'calc(100% / (var(--ratio-width) / var(--ratio-height)))'
    }
  }
}));
