import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  title: {
    marginTop: "80px",
    textAlign: "center",
    font: "normal normal bold 50px/76px Poppins",
    color: "#14183E",
    textTransform: "capitalize",
    marginBottom: "70px",
    [theme.breakpoints.between("xs", "sm")]: {
      font: "normal normal bold 29px/45px Poppins",
      marginBottom: "13%"
    }
  },
  container: {
    marginBottom: "200px"
  }
}));
