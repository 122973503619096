import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  privacyWrapper: {
    width: '90%',
    textAlign: 'left',
    margin: '0 auto',
    padding: '6rem 0',
    whiteSpace: 'pre-line',
    color: theme.palette.neutral['300'],
    counterReset: 'subtitles',

    [theme.breakpoints.up('sm')]: {
      width: '75%'
    },

    [theme.breakpoints.up('md')]: {
      width: '60%'
    }
  },
  privacyHeading: {
    fontSize: '1.875rem',
    lineHeight: theme.typography.lineHeight.heading,
    marginTop: '0',
    marginBottom: '1.5rem',
    paddingBottom: '1rem',
    borderBottom: `1px solid ${theme.palette.neutral['150']}`
  },
  privacyTitle: {
    fontSize: '1.5rem',
    lineHeight: theme.typography.lineHeight.title,
    marginTop: '0',
    marginBottom: '1rem'
  },
  privacySubtitle: {
    fontSize: '1.125rem',
    lineHeight: theme.typography.lineHeight.title,
    marginTop: '0',
    marginBottom: '0.75rem',

    '&::before': {
      counterIncrement: 'subtitles',
      content: 'counter(subtitles) ". "'
    }
  },
  privacyBody: {
    fontSize: '1rem',
    marginTop: '0',
    marginBottom: '0.75rem',
    lineHeight: theme.typography.lineHeight.body,

    '&:last-child': {
      marginBottom: '1.25rem'
    }
  },
  privacyIndentedOl: {
    paddingLeft: '4rem',
    marginTop: '0',
    marginBottom: '0.75rem'
  },
  privacyUl: {
    margin: '0',
    padding: '0',
    listStyle: 'none'
  },
  privacyLi: {
    margin: '0'
  },
  inlineElem: {
    display: 'inline'
  },
  inlineBlockElem: {
    display: 'inline-block'
  },
  topMargin: {
    marginTop: '0.75rem'
  }
}));
