import { makeStyles } from "@material-ui/core";
import DECORE_XL from "../../assets/mobile/Decore@2x.png";
import RECTANGLE_XL from "../../assets/mobile/Rectangle@2x.png";

import IMAGE_XL from "../../assets/web/HomePage_Header@2x.png";
import IMAGE_MOBILE_2X from "../../assets/mobile/Jangl@2x.png";
import IMAGE_MOBILE_SHADOW_2X from "../../assets/mobile/Jangl_shadow@2x.png";
import IMAGE_SHADOW_XL from "../../assets/web/Header_Shadow@2x.png";

import PLATFORM_PAGE_DECORE from "../../assets/platform_page_decor@2.png";
import SOLUTION_BG_IMAGE_2X from "../../assets/web/SolutionPage_Bg@2x.png";
import SOLUTION_PAGE_SHADOW_2X from "../../assets/web/SolutionPage_Shadow@2x.png";
import BOTTOM_DECORE from "../../assets/web/Solution_Bottom_Decor@3x.png";
import SOLUTION_BG_MOBILE_2X from "../../assets/mobile/Solution_Mobile_Bg@2x.png";

export default makeStyles((theme) => ({
  heroTitle: {
    position: "relative",
    font: "80px/140px Roboto Slab",
    lineHeight: theme.typography.lineHeight.heading,
    color: theme.palette.primary.deepTeal,
    //textTransform: "capitalize",
    opacity: "1",
    fontWeight: theme.typography.weights.bold,
    [theme.breakpoints.between("xs", "sm")]: {
      color: theme.palette.neutral["100"]
      // lineHeight: theme.typography.lineHeight.heading,
      //marginTop: "2rem"
    }
  },
  aboutHeroTitle: {
    textAlign: "left",
    maxWidth: "15ch",
    background: `url(${DECORE_XL}) no-repeat`,
    backgroundPosition: "7.5ch",
    backgroundSize: "15rem 0.8rem",
    [theme.breakpoints.between("xs", "sm")]: {
      font: "40px/60px poppins",
      fontWeight: theme.typography.weights.semibold,
      textAlign: "center",
      backgroundImage: `url(${DECORE_XL})`,
      backgroundSize: "10rem 0.5rem",
      backgroundPosition: "8.4ch"
    }
  },
  solutionsHeroTitle: {
    textAlign: "left",
    maxWidth: "20ch",
    background: `url(${DECORE_XL}) no-repeat`,
    backgroundPosition: "left center",
    backgroundSize: "33rem 0.8rem",

    [theme.breakpoints.between("xs", "sm")]: {
      font: "40px/60px poppins",
      fontWeight: theme.typography.weights.semibold,
      maxWidth: "15ch",
      textAlign: "center",
      backgroundImage: `url(${DECORE_XL})`,
      backgroundSize: "20rem 0.5rem",
      backgroundPosition: "left 5vw top 2ch"
    }
  },
  platformHeroTitle: {
    textAlign: "center",
    maxWidth: "23ch",
    background: `url(${DECORE_XL}) no-repeat`,
    backgroundPosition: "left 1rem center",
    backgroundSize: "42rem 0.8rem",
    "&::after": {
      content: '""',
      position: "absolute",
      top: -30,
      right: -50,
      background: `url(${PLATFORM_PAGE_DECORE}) no-repeat`,
      backgroundSize: "contain",
      backgroundPosition: "right  top",
      width: "7rem",
      height: "7rem",
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100px",
        height: "170px",
        backgroundSize: "155%",
        backgroundPosition: "0 0",
        // backgroundColor:"red",
        top: -70,
        right: 0,
        zIndex: -1
      }
    },
    "&::before": {
      content: '""',
      [theme.breakpoints.between("xs", "sm")]: {
        background: `url(${RECTANGLE_XL}) no-repeat`,
        position: "absolute",
        top: -30,
        left: -190,
        width: "19rem",
        height: "13rem",
        filter: "blur(1.25rem)",
        backgroundSize: "contain"
      }
    },
    [theme.breakpoints.between("xs", "sm")]: {
      font: "normal normal bold 38px/58px poppins",
      marginTop: "25vh",
      color: theme.palette.neutral["700"],
      backgroundImage: `url(${DECORE_XL})`,
      backgroundSize: "21rem 0.5rem",
      backgroundPosition: "left 13vw top 2ch"
    }
  },
  heroContainer: {
    position: "relative",
    height: "100%",
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "8%"
    },
    [theme.breakpoints.between("xs", "sm")]: {
      display: "flex",
      flexDirection: "column",
      backgroundImage: "none",
      alignItems: "center"
    },
    "&::before": {
      [theme.breakpoints.up("md")]: {
        content: '""',
        position: "absolute",
        top: "18vh",
        left: -150,
        background: `url(${RECTANGLE_XL}) no-repeat`,
        backgroundSize: "cover",
        backgroundPosition: "right  top",
        width: "19rem",
        height: "20rem",
        filter: "blur(1.25rem)"
      }
    }
  },
  heroTextWrapper: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "25vh"
    },
    [theme.breakpoints.between("xs", "sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto"
    }
  },
  aboutTextWrapper: {
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "20vh"
    }
  },
  platformHeroContainer: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    paddingLeft: 0
  },
  heroPara: {
    font: "19px/25px poppins",
    color: theme.palette.neutral["700"],
    lineHeight: theme.typography.lineHeight.heading,
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      font: "20px/30px poppins",
      textAlign: "center",
      color: theme.palette.neutral["100"],
      marginTop:"2rem",
      marginBottom:"3rem",
    }
  },
  aboutHeroPara: {
    maxWidth: "40ch",
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
      color: theme.palette.neutral["100"],
      maxWidth: "25ch",
      marginTop:"2rem",
      marginBottom:"3rem",
    }
  },
  solutionHeroPara: {
    maxWidth: "41ch",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      width: "300px",
      height: "300px",
      background: `url(${RECTANGLE_XL}) no-repeat`,
      backgroundSize: "contain",

      transform: `translate(-130%)`,
      [theme.breakpoints.between("xs", "sm")]: {
        display: "none"
      }
    },
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
      color: theme.palette.neutral["100"],
      maxWidth: "25ch",
      marginTop:"2rem",
      marginBottom:"3rem",
    }
  },
  scrollIndicator: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    [theme.breakpoints.between("xs", "sm")]: {
      position: "absolute",
      bottom: 20,
      display: "block",
      height: "1.625rem",
      width: "1.625rem"
    }
  },
  hideScrollIndicator: {
    display: "none"
  },
  heroSectionButton: {
    fontSize: "20px",
    fontWeight:theme.typography.weights["500"],
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral["100"],
    borderRadius: 6,
    opacity: "1",
    padding:"0.5rem 2rem 0.5rem 2rem",
    textTransform: "capitalize",
    [theme.breakpoints.up("md")]: {
      marginTop: "5%"
    },
    "&::before": {
      content: '""',
      position: "absolute",
      background: `url(${RECTANGLE_XL}) no-repeat`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: "6rem",
      height: "13rem",
      filter: "blur(1.25rem)",
      zIndex: "-1"
    }
  },
  hideHeroSectionButton: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  heroBackgroundImage: {
    height: "100vh",
    background: `url(${IMAGE_SHADOW_XL}) right top no-repeat,url(${IMAGE_XL}) right top no-repeat`,
    backgroundSize: "61% 100%,61% 100%",
    opacity: 1,
    [theme.breakpoints.between("xs", "sm")]: {
      backgroundImage: `url(${IMAGE_MOBILE_SHADOW_2X}),url(${IMAGE_MOBILE_2X})`,
      backgroundSize: "100% 100%"
    }
  },
  solutionPageBg: {
    height: "100vh",
    backgroundImage: `url(${SOLUTION_PAGE_SHADOW_2X}),url(${SOLUTION_BG_IMAGE_2X}),url(${BOTTOM_DECORE})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%,100% 95%,100% 100%",
    opacity: 1,

    [theme.breakpoints.between("xs", "sm")]: {
      backgroundImage: `url(${IMAGE_MOBILE_SHADOW_2X}),url(${SOLUTION_BG_MOBILE_2X})`,
      backgroundSize: "100% 100%,100% 100%"
    }
  }
}));
