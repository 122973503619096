import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core/";
import janglLogo from "../../../assets/jangl-logo@2x.png";
import styles from "./Footer.styles";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import Facebook from "../../../assets/facebook.svg";
import Instagram from "../../../assets/instagram.svg";
import { withStyles } from "@material-ui/core/styles";
import theme from "../../../CustomTheme.js";
import { Twitter } from "@material-ui/icons";

const Footer = () => {
  const classes = styles();
  const { t } = useTranslation();
  const CustomColorIconButton = withStyles({
    root: {
      color: theme.palette.primary.textDark,
      backgroundColor: "#FFFFFF",
      boxShadow: `0 2px 30px #0000001A`,
      zIndex: 99,
      marginRight: "15px",
      marginLeft: "15px",
      width: "57px",
      height: "57px"
    }
  })(IconButton);
  const fullYear = new Date().getUTCFullYear();
  function socialClick(link) {
    window.open(link, "_blank");
  }
  return (
    <>
      <Grid container className={classes.mainContainer}>
        <Grid container className={classes.containerGrid}>
          <Grid
            md={2}
            item
            className={`${classes.itemGrid} ${classes.footerGrids}`}
          >
            <img src={janglLogo} alt="janglLogo" className={classes.logo} />
          </Grid>
          <Grid md={2} item container className={classes.footerGrids}>
            <Grid item>
              <div item className={classes.itemGrid}>
                <a className={classes.navListTitle}>{t("COMPANY")}</a>
              </div>
              <div item className={classes.itemGrid}>
                <ul className={classes.nav}>
                  <li className={classes.navList}>
                    <a className={classes.navList} href="/about/">
                      {t("ABOUT")}
                    </a>
                  </li>
                  <li className={classes.navList}>
                    <a className={classes.navList} href="/solutions/">
                      {t("SOLUTIONS")}
                    </a>
                  </li>
                  <li className={classes.navList}>
                    <a className={classes.navList} href="/platform/">
                      {t("PLATFORM")}
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
          <Grid md={2} item container className={classes.footerGrids}>
            <Grid item>
              <div item className={classes.itemGrid}>
                <a className={classes.navListTitle}>{t("QUICK_LINKS")}</a>
              </div>
              <div item className={classes.itemGrid}>
                <ul className={classes.nav}>
                  <li className={classes.navList}>
                    <a
                      className={classes.navList}
                      href="https://terms.jangl.com/legal/ccpa/"
                    >
                      {t("CCPA")}
                    </a>
                  </li>
                  <li className={classes.navList}>
                    <a
                      className={classes.navList}
                      href="https://terms.jangl.com/support/contact/"
                    >
                      {t("CONTACT_US")}
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
          <Grid
            md={3}
            container
            item
            direction="column"
            className={classes.footerLastGrid}
          >
            <div className={classes.buttonContainer}>
              <CustomColorIconButton
                onClick={() =>
                  socialClick(
                    "https://www.facebook.com/pages/category/Internet-Company/Janglcom-104609864217928/"
                  )
                }
              >
                <img src={Facebook} alt="Facebook" />
              </CustomColorIconButton>
              <CustomColorIconButton
                onClick={() =>
                  socialClick("https://www.instagram.com/jangl_platform/?hl=en")
                }
              >
                <img src={Instagram} alt="Instagram" />
              </CustomColorIconButton>
              <CustomColorIconButton
                onClick={() => socialClick("https://twitter.com/jangl_corp")}
              >

                <Twitter className={classes.iconStyle} />
              </CustomColorIconButton>
            </div>
            <div className={classes.termsContainer}>
              <a className={classes.navListTerms} href="/legal/terms/">
                {t("TERMS_OF_USE_LABEL")}
              </a>
              <a className={classes.navListTerms} href="/legal/privacy/">
                {t("PRIVACY_POLICY_LABEL")}
              </a>
            </div>
          </Grid>
        </Grid>
        <div className={classes.divider} />
        <Grid item className={classes.itemGridSite}>
          <Grid item>
            <span>&copy;{fullYear} &nbsp;</span>
            {"| Revpoint Media, LLC."}
          </Grid>
          <Grid item>
            &nbsp; <span>{t("ALL_RIGHTS_RESERVED")}</span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Footer;
