import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./ImageText.styles";

export function ImageText({
  title,
  descriptionChild,
  mediaChild,
  mediaType = "image",
  mediaCaption,
  className = "",
  fadeDecorAboutClass = ""
}) {
  const classes = useStyles();

  return (
    <div className={`${classes.featureBlockGrid} ${className}`}>
      {mediaType === "image" && (
        <figure className={`${classes.featureMedia} ${fadeDecorAboutClass}`}>
          {mediaChild}
          {mediaCaption && (
            <figcaption className={classes.featureMediaCaption}>
              {mediaCaption}
            </figcaption>
          )}
        </figure>
      )}
      <div className={classes.featureDescription}>
        <Typography variant="h3" className={classes.featureTitle}>
          {title}
        </Typography>
        <div className={classes.featureDescriptionBody}>{descriptionChild}</div>
      </div>
    </div>
  );
}
