import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  InputAdornment,
  OutlinedInput,
  Typography,
  Slide
} from "@material-ui/core";

import SendIcon from "../../assets/send_icon.svg";
import EmailIcon from "../../assets/email_icon.svg";
import useStyles from "./RequestDemo.styles";
import { useSnackbar } from "notistack";
import axiosInstance from "../../services/api";

export function RequestDemo() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const action = (key) => (
    <Button
      className={classes.actionBtn}
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      {t("DISMISS")}
    </Button>
  );
  const onHandleRequestDemo = async () => {
    if (email?.length) {
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(email)) {
        enqueueSnackbar(t("INVALID_EMAIL"), {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          TransitionComponent: Slide
        });
      } else {
        const res = await axiosInstance.post("/api/demo-requests", {
          email: email
        });
        setEmail("");
        res?.data?.success
          ? enqueueSnackbar(t("EMAIL_SUCCESS_SUBMIT"), {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              TransitionComponent: Slide,
              persist: "true",
              action
            })
          : enqueueSnackbar(`${res?.data?.errorMessage}`, {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              hideIconVariant: false,
              TransitionComponent: Slide,
              persist: "true",
              action
            });
      }
    }
  };

  return (
    <div id="request-demo" className={classes.requestDemoWrapper}>
      <div className={classes.requestDemoDecorationBadge}>
        <img
          role="presentation"
          src={SendIcon}
          alt="Send Icon"
          className={classes.requestDemoDecorationIcon}
        />
      </div>
      <div className={classes.requestDemoContent}>
        <Typography variant="h2" className={classes.requestDemoTitle}>
          {t("REQUEST_A_DEMO_TITLE")}
        </Typography>
        <Typography variant="body2" className={classes.requestDemoBodyCopy}>
          {t("REQUEST_A_DEMO_BODY")}
        </Typography>
        <form className={classes.requestDemoForm}>
          <OutlinedInput
            id="request-demo-email"
            type="email"
            name="request-demo-email"
            value={email}
            placeholder={t("EMAIL_INPUT_PLACEHOLDER")}
            onChange={(e) => setEmail(e.target.value)}
            className={classes.requestDemoEmailInput}
            startAdornment={
              <InputAdornment position="start">
                <img src={EmailIcon} alt="Email placeholder icon" />
              </InputAdornment>
            }
          />
          <Button
            variant="contained"
            disableElevation
            disabled={!email?.length}
            className={classes.requestDemoBtn}
            color="primary"
            onClick={() => onHandleRequestDemo()}
          >
            {t("SETUP_DEMO_BTN_TEXT")}
          </Button>
        </form>
      </div>
    </div>
  );
}
