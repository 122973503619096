import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Link } from "@material-ui/core";
import Header from "../../containers/Header/Header.component";
import useStyles from "./PrivacyPolicy.styles";

export function PrivacyPolicy() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
    <Header withBackground={false}/>
    <div className={classes.privacyWrapper}>
      <section>
        <Typography variant="h2" className={classes.privacyHeading}>
          {t("PRIVACY_POLICY.PAGE_HEADING")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.MAIN_PARA_1")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.MAIN_PARA_2")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.privacyTitle}>
          {t("PRIVACY_POLICY.DESCRIPTION_OF_USERS_TITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.DESCRIPTION_OF_USERS_PARA_1")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.DESCRIPTION_OF_USERS_PARA_2")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.DESCRIPTION_OF_USERS_PARA_3")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.privacyTitle}>
          {t("PRIVACY_POLICY.INFO_WE_COLLECT_TITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.INFO_WE_COLLECT_PARA_1")}
        </Typography>
        <Typography variant="h4" className={classes.privacySubtitle}>
          {t("PRIVACY_POLICY.PERSONAL_INFO_SUBTITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.PERSONAL_INFO_PARA")}
        </Typography>
        <Typography variant="h4" className={classes.privacySubtitle}>
          {t("PRIVACY_POLICY.BILLING_INFO_SUBTITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.BILLING_INFO_PARA")}
        </Typography>
        <Typography variant="h4" className={classes.privacySubtitle}>
          {t("PRIVACY_POLICY.ORDER_INFO_SUBTITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.ORDER_INFO_PARA")}
        </Typography>
        <Typography variant="h4" className={classes.privacySubtitle}>
          {t("PRIVACY_POLICY.USER_DATA_SUBTITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.USER_DATA_PARA")}
        </Typography>
        <Typography variant="h4" className={classes.privacySubtitle}>
          {t("PRIVACY_POLICY.OTHER_INFO_SUBTITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.OTHER_INFO_PARA")}
        </Typography>
        <ol type="a" className={classes.privacyIndentedOl}>
          <li className={classes.privacyBody}>
            <span>{t("PRIVACY_POLICY.FROM_YOU_LIST_TITLE")}</span>&nbsp;
            <span>{t("PRIVACY_POLICY.FROM_YOU_LIST_PARA")}</span>
          </li>
          <li className={classes.privacyBody}>
            <span>{t("PRIVACY_POLICY.FROM_YOUR_ACTIVITY_LIST_TITLE")}</span>&nbsp;
            <span className={`${classes.inlineBlockElem} ${classes.privacyBody}`}>
              {t("PRIVACY_POLICY.FROM_YOUR_ACTIVITY_PARA")}
            </span>
            <ul className={classes.privacyUl}>
              <li className={`${classes.privacyBody} ${classes.privacyLi}`}>
                {t("PRIVACY_POLICY.FROM_YOUR_ACTIVITY_BULLET_1")}
              </li>
              <li className={`${classes.privacyBody} ${classes.privacyLi}`}>
                {t("PRIVACY_POLICY.FROM_YOUR_ACTIVITY_BULLET_2")}
              </li>
              <li className={`${classes.privacyBody} ${classes.privacyLi}`}>
                {t("PRIVACY_POLICY.FROM_YOUR_ACTIVITY_BULLET_3")}
              </li>
            </ul>
          </li>
          <li className={classes.privacyBody}>
            <span>{t("PRIVACY_POLICY.FROM_COOKIES_LIST_TITLE")}</span>&nbsp;
            <span>{t("PRIVACY_POLICY.FROM_COOKIES_PARA")}</span>
          </li>
        </ol>
      </section>

      <section>
        <Typography variant="h3" className={classes.privacyTitle}>
          {t("PRIVACY_POLICY.3RD_PARTY_INFO_COLLECTED_TITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.3RD_PARTY_INFO_COLLECTED_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.privacyTitle}>
          {t("PRIVACY_POLICY.ACCESSING_SHARING_INFO_TITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.ACCESSING_SHARING_INFO_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.privacyTitle}>
          {t("PRIVACY_POLICY.HOW_WE_USE_INFO_TITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.HOW_WE_USE_INFO_PARA_1")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.HOW_WE_USE_INFO_PARA_2")}
        </Typography>
        <ul className={classes.privacyUl}>
          <li className={`${classes.privacyBody} ${classes.privacyLi}`}>
            {t("PRIVACY_POLICY.HOW_WE_USE_INFO_BULLET_1")}
          </li>
          <li className={`${classes.privacyBody} ${classes.privacyLi}`}>
            {t("PRIVACY_POLICY.HOW_WE_USE_INFO_BULLET_2")}
          </li>
          <li className={`${classes.privacyBody} ${classes.privacyLi}`}>
            {t("PRIVACY_POLICY.HOW_WE_USE_INFO_BULLET_3")}
          </li>
          <li className={`${classes.privacyBody} ${classes.privacyLi}`}>
            {t("PRIVACY_POLICY.HOW_WE_USE_INFO_BULLET_4")}
          </li>
          <li className={`${classes.privacyBody} ${classes.privacyLi}`}>
            {t("PRIVACY_POLICY.HOW_WE_USE_INFO_BULLET_5")}
          </li>
          <li className={`${classes.privacyBody} ${classes.privacyLi}`}>
            {t("PRIVACY_POLICY.HOW_WE_USE_INFO_BULLET_6")}
          </li>
          <li className={`${classes.privacyBody} ${classes.privacyLi}`}>
            {t("PRIVACY_POLICY.HOW_WE_USE_INFO_BULLET_7")}
          </li>
          <li className={`${classes.privacyBody} ${classes.privacyLi}`}>
            {t("PRIVACY_POLICY.HOW_WE_USE_INFO_BULLET_8")}
          </li>
        </ul>
      </section>

      <section>
        <Typography variant="h3" className={classes.privacyTitle}>
          {t("PRIVACY_POLICY.HOW_WE_PROTECT_INFO_TITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.HOW_WE_PROTECT_INFO_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.privacyTitle}>
          {t("PRIVACY_POLICY.NON_U_S_NOTICE_TITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.NON_U_S_NOTICE_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.privacyTitle}>
          {t("PRIVACY_POLICY.CHILDREN_TITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.CHILDREN_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.privacyTitle}>
          {t("PRIVACY_POLICY.CALIFORNIA_RESIDENTS_TITLE")}
        </Typography>
        <Typography className={`${classes.privacyBody} ${classes.inlineElem}`}>
          {t("PRIVACY_POLICY.CALIFORNIA_RESIDENTS_PARA_1_PART_1")}
        </Typography>
        <Link
          href="mailto:connect@jangl.com"
          underline="none"
          color="primary"
          target="_blank"
          rel="noopener"
          className={`${classes.privacyBody} ${classes.inlineElem}`}
        >
          connect@jangl.com
        </Link>&nbsp;
        <Typography className={`${classes.privacyBody} ${classes.inlineBlockElem}`}>
          {t("PRIVACY_POLICY.CALIFORNIA_RESIDENTS_PARA_1_PART_2")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.CALIFORNIA_RESIDENTS_PARA_2")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.privacyTitle}>
          {t("PRIVACY_POLICY.EXTERNAL_WEBSITES_TITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.EXTERNAL_WEBSITES_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.privacyTitle}>
          {t("PRIVACY_POLICY.CHANGES_TO_POLICY_TITLE")}
        </Typography>
        <Typography className={classes.privacyBody}>
          {t("PRIVACY_POLICY.CHANGES_TO_POLICY_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.privacyTitle}>
          {t("PRIVACY_POLICY.HOW_TO_CONTACT_TITLE")}
        </Typography>
        <Typography className={`${classes.privacyBody} ${classes.inlineElem}`}>
          {t("PRIVACY_POLICY.HOW_TO_CONTACT_PARA_1_PART_1")}
        </Typography>
        <Link
          href="mailto:connect@jangl.com"
          underline="none"
          color="primary"
          target="_blank"
          rel="noopener"
          className={`${classes.privacyBody} ${classes.inlineElem}`}
        >
          connect@jangl.com
        </Link>
        <Typography className={`${classes.privacyBody} ${classes.inlineElem}`}>
          {t("PRIVACY_POLICY.HOW_TO_CONTACT_PARA_1_PART_2")}
        </Typography>
        <Typography className={`${classes.privacyBody} ${classes.topMargin}`}>
          {t("PRIVACY_POLICY.HOW_TO_CONTACT_PARA_2")}
        </Typography>
      </section>
    </div>
    </div>
  );
}
