import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 16,
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700
    },
    lineHeight: {
      heading: 1.25,
      title: 1.4,
      body: 1.625,
      button: 1.5,
      bodyLarge: 2.5
    }
  },
  palette: {
    primary: {
      light: "hsl(26, 95%, 93%)", //#feebdc
      main: "hsl(26, 100%, 57%)", //#ff8324
      dark: "hsl(26, 100%, 49%)", //#fa6c00
      dropShadow: "hsla(0, 0%, 0%, 0.16)",
      orange: "hsla(26, 100%, 57%, 1)",
      deepTeal: "#181E4B",
      light_2: "rgba(255, 150, 75, 0.2)"
    },
    secondary: {
      main: "hsl(253, 73%, 56%)"
    },
    neutral: {
      100: "hsl(0, 0%, 100%)", //#ffffff
      150: "hsl(0, 0%, 93%)", //#ededed
      200: "hsl(233, 16%, 44%)", // #5e6282
      300: "hsl(225, 24%, 29%)", // #38415c
      400: "hsl(233, 52%, 19%)", // #171d4a
      500: "hsl(234, 51%, 16%)", //	#14183e
      600: "hsl(240, 6%, 3%)", //#070708
      700: "hsl(0, 0%, 0%)" // #000000
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
});
