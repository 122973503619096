import React, { useEffect } from "react";
import { Typography, Button } from "@material-ui/core";
import useStyles from "./hero.styles";
import SCROLL_DOWN_ARROW from "../../assets/mobile/Arrow@2x.png";

export function HeroComponent({
  title,
  content,
  buttonLabel,
  containerClass,
  titleClass,
  paraClass,
  hideHeroSectionBtnClass,
  hideScrollClass,
  textWrapper
}) {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={`${classes.heroContainer} ${containerClass}`}>
      <div className={`${classes.heroTextWrapper} ${textWrapper}`}>
        {title && (
          <Typography
            variant="h1"
            gutterBottom
            className={`${classes.heroTitle}  ${titleClass}`}
          >
            {title}
          </Typography>
        )}
        {content && (
          <Typography paragraph className={`${classes.heroPara} ${paraClass}`}>
            {content}
          </Typography>
        )}
        {buttonLabel && (
          <Button
            variant="contained"
            className={`${classes.heroSectionButton} ${hideHeroSectionBtnClass}`}
            href="#request-demo"
          >
            {buttonLabel}
          </Button>
        )}
      </div>
      <img
        src={SCROLL_DOWN_ARROW}
        alt="down_arron"
        className={`${classes.scrollIndicator} ${hideScrollClass}`}
      />
    </div>
  );
}
