import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// translations
import enLocale from "./locale/en.json";

const resources = {
  en: {
    translation: enLocale
  }
};

// initiate i18n instance
i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  keySeparator: ".",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
