import { makeStyles } from "@material-ui/core/styles";
import RECTANGLE_XL from "../../assets/web/ImageListFade@2x.png";

export default makeStyles((theme) => ({
  img: {
    width: "50%",
    margin: "auto",
    display: "block",
    borderColor: theme.palette.primary.main,
    borderRadius: "8px",
    padding: "10px",
    border: "solid 3px",
    maxWidth: "100px"
  },
  borderRadiusImg: {
    borderRadius: "60px 0px",
    padding: "50px 30px",
    minHeight: "200px",
    margin: "22px",
    // zIndex: 99,
    position: "relative",

    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "35%",
      filter: "blur(50)",
      background: `url(${RECTANGLE_XL}) no-repeat`,
      backgroundSize: "cover",
      transform: `rotate(180deg) translate(0,-100%)`
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "148px"
    }
  },
  borderRadiusImgMargin: {
    borderRadius: "60px 0px",
    padding: "50px 30px",
    minHeight: "200px",
    margin: "22px",
    zIndex: 99,
    position: "relative",

    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "35%",
      filter: "blur(50)",
      background: `url(${RECTANGLE_XL}) no-repeat`,
      backgroundSize: "cover",
      transform: `rotate(180deg) translate(0,-100%)`
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "148px"
    }
  },
  cardTitle: {
    font: "normal normal medium Poppins",
    fontSize: "3.125rem",
    lineHeight: theme.typography.lineHeight.title,
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    opacity: "1",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
      fontSize: "2rem",
      lineHeight: theme.typography.lineHeight.title
    }
  },
  cardDetail: {
    fontFamily: "Poppins",
    fontSize: "17px",
    lineHeight: "26px",
    maxWidth: "19ch",
    fontWeight: theme.typography.weights.semiBold,
    color: theme.palette.neutral["700"],
    textAlign: "center",
    margin: "auto",
    paddingTop: "30px"
    //display: "block",
  },
  title: {
    font: "medium Poppins",
    fontSize: "32px",
    lineHeight: "48px",
    color: theme.palette.neutral["700"],
    textAlign: "center",
    margin: "46px"
  },
  subTitle: {
    font: "medium Poppins",
    fontSize: "18px",
    fontWeight: theme.typography.weights.medium,
    lineHeight: "50px",
    color: theme.palette.neutral["700"],
    textAlign: "center",
    width: "100%",
    padding: "39px"
  },
  mainGrid: {
    marginTop: "50px",
    [theme.breakpoints.between("xs", "sm")]: {
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "1.25rem"
    }
  },
  imageGridCover: {
    [theme.breakpoints.between("xs", "sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  }
}));
