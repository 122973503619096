import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  featureBlockGrid: {
    display: "grid",
    gridGap: "4rem",
    alignItems: "center",
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "2fr 3fr"
    }
  },
  featureMedia: {
    margin: "0",
    textAlign: "center",

    "& img": {
      display: "block",
      maxWidth: "100%",
      borderRadius: "1.75rem"
    }
  },
  featureMediaCaption: {
    display: "inline-block",
    marginTop: "3rem",
    fontSize: "2.5rem",
    lineHeight: theme.typography.lineHeight.title,
    fontWeight: theme.typography.weights.semiBold,
    color: theme.palette.neutral["700"],
    position: "relative",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.75rem"
    },
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "-0.5rem",
      height: "0.25rem",
      width: "60%",
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: theme.palette.primary.main
    }
  },
  featureDescription: {
    textAlign: "center",
    direction: "initial",
    fontWeight: theme.typography.weights.medium,

    "& p:not(:last-child)": {
      marginBottom: "1rem"
    },

    [theme.breakpoints.up("md")]: {
      textAlign: "left"
    }
  },
  featureTitle: {
    fontSize: "clamp(1.5rem, 2.6vw, 4rem)",
    textTransform: "capitalize",
    lineHeight: theme.typography.lineHeight.title,
    fontWeight: theme.typography.weights.medium,
    marginBottom: "3.5rem",
    paddingTop: "3rem",
    color: theme.palette.primary.main,
    [theme.breakpoints.between("xs", "sm")]: {
      fontWeight: theme.typography.weights.semiBold,
      paddingTop: "0",
    }
  },
  featureDescriptionBody: {
    fontSize: "1.125rem",
    lineHeight: theme.typography.lineHeight.bodyLarge,
    color: theme.palette.neutral["700"],

    "& p": {
      margin: "0"
    },

    [theme.breakpoints.up("md")]: {
      fontSize: "1.25rem"
    }
  },
  aboutFeatureImg: {
    objectFit: "cover"
  },
  featureBlockRightMedia: {
    direction: "rtl"
  },
  cornerDecorSquare: {
    position: "relative",

    "&::after": {
      content: '""',
      position: "absolute",
      height: "4rem",
      width: "4rem",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "0.625rem 0 0.625rem 0",
      zIndex: "-1"
    },

    [theme.breakpoints.up("md")]: {
      "&::after": {
        borderRadius: "1.875rem 0 0.625rem 0"
      }
    }
  },
  cornerDecorSquareBottomLeft: {
    "&::after": {
      bottom: "0",
      left: "0",
      transform: "translate(-40%, 40%)"
    }
  },
  cornerDecorSquareBottomRight: {
    "&::after": {
      bottom: "0",
      right: "0",
      transform: "translate(40%, 40%)"
    }
  },
  cornerDecorSquareTopLeft: {
    "&::after": {
      top: "0",
      left: "0",
      transform: "translate(-40%, -40%)"
    }
  },
  cornerDecorSquareTopRight: {
    "&::after": {
      top: "0",
      right: "0",
      transform: "translate(40%, -40%)"
    }
  },
  cornerDecorSquareTopRightAltered: {
    "&::after": {
      top: "0",
      right: "0",
      borderRadius: "0 0.625rem 0 0",
      transform: "translate(40%, -40%)"
    }
  }
}));
