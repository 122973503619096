import React from "react";
import { Switch, Redirect } from "react-router-dom";
import CustomRoute from "./CustomRoute";
import { PrivacyPolicy, TermsOfUse } from "../../components";
import Solutions from "../Solutions/Solutions";
import About from "../About/About";
import Platform from "../Platform/Platform";

export const CustomRoutes = () => {
  const NotFoundRedirect = () => <Redirect to="/about/" />;

  return (
    <Switch>
      <CustomRoute exact path='/about/' component={About} />
      <CustomRoute exact path='/solutions/' component={Solutions} />
      <CustomRoute exact path='/platform/' component={Platform} />
      <CustomRoute exact path='/legal/privacy' component={PrivacyPolicy} />
      <CustomRoute exact path='/legal/terms' component={TermsOfUse} />
      <CustomRoute component={NotFoundRedirect} />
    </Switch>
  );
};

export default CustomRoutes;
