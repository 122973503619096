import { makeStyles } from "@material-ui/core/styles";
import FADE_DECORE from "../../assets/mobile/Rectangle@2x.png";

export default makeStyles((theme) => ({
  title: {
    marginTop: "90px",
    textAlign: "center",
    font: "normal normal 600 18px/27px Poppins",
    color: "#FF8022",
    textTransform: "capitalize",
    [theme.breakpoints.between("xs", "sm")]: {
      font: "normal normal bold 18px/27px Poppins",
      
    }
  },
  subtitle: {
    textAlign: "center",
    font: "normal normal bold 50px/76px Poppins",
    marginBottom: "100px",
    [theme.breakpoints.between("xs", "sm")]: {
      font: "normal normal bold 32px/48px Poppins",
      margin:0
    }
  },
  tempRootGrid: {
    gridGap: "2rem !important"
  },
  fadeDecor: {
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      width: "200px",
      height: "300px",
      background: `url(${FADE_DECORE}) no-repeat`,
      backgroundSize: "cover",
      backgroundPosition: "left",
      filter: "blur(2.5rem)",
      top: "100vh",
      right: 0,
      [theme.breakpoints.between("xs", "sm")]: {
        display: "none"
      }
    }
  }
}));
