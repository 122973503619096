import React from "react";
import { PlayArrowRounded } from "@material-ui/icons";

import useStyles from "./BulletList.styles";

export function BulletList({
  listTitle,
  listItems,
  columns = { sm: 1, lg: 1 }
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <p className={classes.listTitle}>
        {listTitle}
      </p>
      <ul
        style={{
          '--list-columns-sm': columns.sm,
          '--list-columns-lg': columns.lg
        }}
        className={classes.bulletList}
      >
        {listItems.map((item, index) => {
          return (
            <li key={index} className={classes.bulletListItem}>
              <PlayArrowRounded color="primary" className={classes.bulletListIcon} />
              {item}
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
}
