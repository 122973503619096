import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

const CustomRoute = (props) => {

  const { t } = useTranslation();
  const [returnedRoute, setReturnedRoute] = useState("");
  const dispatch = useDispatch();

  /**
   * Redirects to  error page
   * @param {*} pageName
   */
  const redirectToErrorPage = (pageName) => {
    return (
      <Redirect
        to={{
          pathname: "/error",
          pageName: pageName,
        }}
      />
    );
  };

  useEffect(() => {
    return setReturnedRoute(<Route {...props} />);
  }, [props]);

  return <>{returnedRoute}</>;
};

export default CustomRoute;
