import { makeStyles } from "@material-ui/core/styles";
import FADE_DECORE from "../../assets/mobile/Rectangle@2x.png";

export default makeStyles((theme) => ({
  fadeDecorAbout: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "300px",
      height: "300px",
      display: "block",
      background: `url(${FADE_DECORE}) no-repeat`,
      backgroundSize: "100% 150%",
      backgroundPosition: "100%",
      top: 0,
      [theme.breakpoints.between("xs", "sm")]: {
        display: "none"
      }
    }
  },
  fadeDecorAboutPosition2: {
    "&::before": {
      left: 0,
      transform: `translate(-40%, 50%)`
    }
  },
  fadeDecorAboutPosition1: {
    "&::before": {
      right: 0,
      transform: `translate(30%,20%)`
    }
  }
}));
