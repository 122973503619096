import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  termsWrapper: {
    width: '90%',
    textAlign: 'left',
    margin: '0 auto',
    padding: '6rem 0',
    whiteSpace: 'pre-line',
    color: theme.palette.neutral['300'],
    counterReset: 'titles',

    [theme.breakpoints.up('sm')]: {
      width: '75%'
    },

    [theme.breakpoints.up('md')]: {
      width: '60%'
    }
  },
  termsHeading: {
    fontSize: '1.875rem',
    lineHeight: theme.typography.lineHeight.heading,
    marginTop: '0',
    marginBottom: '1.5rem',
    paddingBottom: '1rem',
    borderBottom: `1px solid ${theme.palette.neutral['150']}`
  },
  termsTitle: {
    fontSize: '1.5rem',
    lineHeight: theme.typography.lineHeight.title,
    marginTop: '0',
    marginBottom: '1rem',
    textTransform: 'uppercase',

    '&::before': {
      counterIncrement: 'titles',
      content: 'counter(titles) ". "'
    }
  },
  termsBody: {
    fontSize: '1rem',
    marginTop: '0',
    marginBottom: '0.75rem',
    lineHeight: theme.typography.lineHeight.body,

    '&:last-child': {
      marginBottom: '1.25rem'
    }
  },
  termsLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'none',

    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  termsDlDt: {
    fontWeight: theme.typography.weights.semiBold,
    textAlign: 'right'
  },
  termsIndentedDl: {
    paddingLeft: '4rem',
    marginTop: '0',
    marginBottom: '0.75rem',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: '0 1rem',

    '& > *': {
      margin: '0'
    }
  },
  termsUl: {
    margin: '0',
    padding: '0',
    listStyle: 'none'
  },
  termsLi: {
    margin: '0'
  },
  inlineElem: {
    display: 'inline'
  },
  inlineBlockElem: {
    display: 'inline-block'
  },
  topMargin: {
    marginTop: '1.25rem'
  }
}));
