import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import useStyles from "./ImageList.styles";
import useCommonStyles from "../../commonStyles";

import { useTranslation } from "react-i18next";
import IMAGE_1 from "../../assets/marketplace.svg";
import IMAGE_2 from "../../assets/web-campaigns.svg";
import IMAGE_3 from "../../assets/platform.svg";
import IMAGE_4 from "../../assets/reports.svg";
import theme from "../../CustomTheme.js";

export function ImageList({
  title = "SOLUTIONS_PAGE.TITLE",
  subtitle = "SOLUTIONS_PAGE.SUBTITLE",
  images = [
    { title: "SOLUTIONS_PAGE.IMAGE_1", image: IMAGE_1, topMargin: false },
    { title: "SOLUTIONS_PAGE.IMAGE_2", image: IMAGE_2, topMargin: true },
    { title: "SOLUTIONS_PAGE.IMAGE_3", image: IMAGE_3, topMargin: false },
    { title: "SOLUTIONS_PAGE.IMAGE_4", image: IMAGE_4, topMargin: true }
  ]
}) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.mainGrid}>
      <Typography
        className={`${classes.cardTitle} ${commonClasses.decorPlusIconsSolutions}`}
      >
        {t(title)}
      </Typography>
      <Typography className={classes.subTitle}>{t(subtitle)}</Typography>

      {images.map((item) => (
        <Grid item container md={3} className={classes.imageGridCover}>
          <Box
            boxShadow={5}
            className={
              item.topMargin
                ? classes.borderRadiusImgMargin
                : classes.borderRadiusImg
            }
          >
            <img className={classes.img} alt="jangl-image" src={item.image} />
            {/* <IMAGE_1 style={{ fill: theme.palette.primary.main }}/> */}
            <Typography className={classes.cardDetail}>
              {t(item.title)}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
