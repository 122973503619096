import * as types from "./types";

const reducer = (state = 0, action) => {
  switch (action.type) {
    case types.UI_START_LOADING:
      return state + 1;
    case types.UI_STOP_LOADING:
      return state - 1;
    default:
      return state;
  }
};

export default reducer;
