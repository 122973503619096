import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    background: "white",
    left: 0,
    bottom: 0,
    color: theme.palette.neutral["200"],
    fontFamily: "normal normal Poppins",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "1.25rem"
    }
  },
  links: {
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "2.5rem"
    }
  },
  // containerGrid: {
  //   padding: theme.spacing(3, 16, 2)
  // },
  itemGridSite: {
    fontFamily: "Poppins",
    fontSize: "18px/26px",
    fontWeight: theme.typography.weights.medium,
    marginBottom: "59px",
    minWidth: "300px",
    textAlign: "center",
    color: theme.palette.neutral["700"],
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center"
    }
  },
  divider: {
    width: "90%",
    height: "2px",
    background: theme.palette.primary.orange,
    marginBottom: "30px",
    marginTop: "30px",
    opacity: "0.34"
  },
  gridSpan: {
    font: "Bold 14px/18px Poppins",
    fontWeight: theme.typography.weights.bold
  },
  itemGrid: {
    display: "flex",
    width: "100%",
    margin: "auto",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  },
  nav: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: theme.typography.weights.medium,
    listStyle: "none",
    margin: 5,
    textAlign: "left",
    cursor: "pointer",
    paddingLeft: 0
  },
  text: {
    textAlign: "left"
  },
  iconStyle: { fill: theme.palette.neutral["700"] },
  navListTitle: {
    fontFamily: "Poppins",
    color: theme.palette.neutral["600"],
    fontSize: "21px",
    fontWeight: theme.typography.weights.bold,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main
    },
    marginBottom: "20px"
  },
  navList: {
    color: theme.palette.neutral["200"],
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: theme.typography.weights.medium,
    textDecoration: "none",
    paddingBottom: "10px",
    "&:hover": {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.between("xs", "sm")]: {
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center"
    }
  },
  navListTerms: {
    color: theme.palette.neutral["700"],
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: theme.typography.weights.medium,
    textDecoration: "none",
    paddingBottom: "10px",
    "&:hover": {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.between("xs", "sm")]: {
      justifyContent: "center",
      alignItems: "center"
    }
  },
  buttonContainer: {
    marginBottom: "58px",
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "58px",
      justifyContent: "center",
      alignItems: "center"
    }
  },
  logo: {
    width: "200px",
    marginBottom: "20px",
    marginTop: "-120px !important"
  },
  copyRight: {
    height: "5px"
  },
  footerGrids: {
    justifyContent: "flex-start",
    [theme.breakpoints.between("xs", "sm")]: {
      justifyContent: "center"
    }
  },
  footerLastGrid: {
    alignContent:"flex-end",
    marginRight:"90px",
    [theme.breakpoints.between("xs", "sm")]: {
      justifyContent: "center",
      alignItems: "center",
      alignContent:"center",
      marginRight:"0px"
    }
  },
  termsContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center"
    }
  },
  "@media only screen and (max-width:991px)": {
    containerGrid: {
      padding: theme.spacing(2, 10, 2)
    }
  },
  "@media only screen and (max-width:767px)": {
    containerGrid: {
      padding: theme.spacing(2, 2, 2)
    }
  }
}));
