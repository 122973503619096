import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Typography, Grid } from "@material-ui/core";

import {
  ImageText,
  RequestDemo,
  HeroComponent,
  FixedAspectRatioImage,
  ImageList
} from "../../components";
import clsx from "clsx";
import useHeroStyles from "../../components/Hero/hero.styles";
import useImgTextStyles from "../../components/ImageText/ImageText.styles";
import Header from "../Header/Header.component";
import useStyles from "./Solutions.style";
import useCommonStyles from "../../commonStyles";

import SOLUTIONS_BUYER_ACCESS from "../../assets/solutions__buyer_access.png";
import SOLUTIONS_BUYER_IN_CTRL from "../../assets/solutions__buyer_in_control.png";
import SOLUTIONS_LEAD_PERF from "../../assets/solutions__lead_perf.png";
import SOLUTIONS_POWERFUL_TOOL from "../../assets/solutions__powerful_tool.png";

export default function Solutions(props) {
  const { t } = useTranslation();
  const classes = useHeroStyles();
  const pageClasses = useStyles();
  const imgTextClasses = useImgTextStyles();
  const commonClasses = useCommonStyles();

  return (
    <div>
      <div className={clsx(classes.solutionPageBg, pageClasses.fadeDecor)}>
        <Header />
        <HeroComponent
          title={t("SOLUTION.HERO_TITLE")}
          content={t("SOLUTION.HERO_DESC")}
          buttonLabel={t("SETUP_DEMO_BTN_TEXT")}
          titleClass={classes.solutionsHeroTitle}
          paraClass={classes.solutionHeroPara}
          hideHeroSectionBtnClass={classes.hideHeroSectionButton}
        />
      </div>
      <Container
        className={clsx(commonClasses.tempRootGrid, pageClasses.tempRootGrid)}
      >
        <ImageList />
        <Typography className={pageClasses.title}>
          {t("SOLUTIONS_TITLE")}
        </Typography>
        <Typography className={pageClasses.subtitle}>
          {t("SOLUTION.HOW_CAN")}
        </Typography>
        <div className={commonClasses.featureBlocksWrapper}>
          <ImageText
            title={t("JANGL_ALLOWS_BUYERS_TITLE")}
            descriptionChild={<p>{t("JANGL_ALLOWS_BUYERS_CONTENT")}</p>}
            mediaChild={
              <FixedAspectRatioImage
                src={SOLUTIONS_BUYER_ACCESS}
                imgClass={imgTextClasses.aboutFeatureImg}
                boxClass={commonClasses.featureBlocksMediaBox}
              />
            }
          />
          <ImageText
            title={t("JANGL_POWERFUL_TOOL_TITLE")}
            className={imgTextClasses.featureBlockRightMedia}
            descriptionChild={<p>{t("JANGL_POWERFUL_TOOL_CONTENT")}</p>}
            mediaChild={
              <FixedAspectRatioImage
                src={SOLUTIONS_POWERFUL_TOOL}
                imgClass={imgTextClasses.aboutFeatureImg}
                boxClass={commonClasses.featureBlocksMediaBox}
              />
            }
          />
          <ImageText
            title={t("JANGL_PUTS_BUYER_CONTROL_TITLE")}
            descriptionChild={<p>{t("JANGL_PUTS_BUYER_CONTROL_CONTENT")}</p>}
            mediaChild={
              <FixedAspectRatioImage
                src={SOLUTIONS_BUYER_IN_CTRL}
                imgClass={imgTextClasses.aboutFeatureImg}
                boxClass={commonClasses.featureBlocksMediaBox}
              />
            }
          />
          <ImageText
            title={t("LEAD_PERFORMANCE_IS_A_BYPRODUCT_TITLE")}
            className={imgTextClasses.featureBlockRightMedia}
            descriptionChild={
              <p>{t("LEAD_PERFORMANCE_IS_A_BYPRODUCT_CONTENT")}</p>
            }
            mediaChild={
              <FixedAspectRatioImage
                src={SOLUTIONS_LEAD_PERF}
                imgClass={imgTextClasses.aboutFeatureImg}
                boxClass={commonClasses.featureBlocksMediaBox}
              />
            }
          />
        </div>
      </Container>
      <RequestDemo />
    </div>
  );
}
