import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  appbarStyling: {
    backgroundColor: "transparent",
    position: "fixed"
  },
  greyBg: {
    backgroundColor: theme.palette.neutral["100"]
  },
  darkFontColor: {
    textDecoration: "none",
    color: theme.palette.neutral["700"]
  },
  inactiveFontColor: {
    color: theme.palette.neutral["100"]
  },
  primaryFontColor: {
    color: theme.palette.primary.main
  },
  btnGridContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end"
    }
  },
  appBarMainGrid: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "8%",
      marginRight: "4%"
    }
  },
  textLeft: { textAlign: "left" },
  btns: {
    fontSize: "1.1rem",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem"
    }
  },

  toggleBtn: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      fontSize: "1rem"
    }
  },
  appBarTextBtnActive: {
    color: theme.palette.primary.main,
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: -8,
      left: "1.4ch",
      width: "50%",
      height: 2,
      backgroundColor: theme.palette.primary.main
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  hideAppBarTextBtn: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  logo: {
    width: "9rem",
    [theme.breakpoints.down("sm")]: {
      width: "7rem"
    }
  },
  btnLogin: {
    padding: 12,
    borderRadius: 6,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  btnDemo: {
    marginRight: "0.8rem",
    padding: 8,
    borderStyle: "solid",
    color: theme.palette.primary.main,
    borderWidth: "2px",
    borderRadius: 6,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
      padding: 5,
      marginRight: 0,
      borderWidth: "1px",
      borderRadius: 6
    }
  },
  menuLoginBttn: {
    backgroundColor: theme.palette.primary.main
  },
  menuIconStyling: { width: "2rem", height: "1rem" }
}));
