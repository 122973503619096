import * as types from "./types";

const uiStartLoading = () => {
  return {
    type: types.UI_START_LOADING
  };
};

const uiStopLoading = () => {
  return {
    type: types.UI_STOP_LOADING
  };
};

export { uiStartLoading, uiStopLoading };
