import React, { Fragment } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Grid from "@material-ui/core/Grid";
import { NavLink, Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import useStyles from "./Header.styles";
import JANGL_LOGO_2X from "../../assets/jangl-logo.png";
import JANGL_LOGO_3X from "../../assets/jangl-logo@3x.png";
import MENUICON from "../../assets/mobile/menuIcon.png";

import useScrollTrigger from "@material-ui/core/useScrollTrigger";

export default function HeaderBar({withBackground = true}) {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [headerValue, setHeaderValue] = React.useState(0);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });
  const RequestDemoLink = React.forwardRef((props, ref) => (
    <a {...props}>{props.children}</a>
  ));
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        elevation={trigger ? 4 : 0}
        className={`${classes.appbarStyling} ${trigger && classes.greyBg}`}
      >
        <Toolbar>
          <Grid container className={classes.appBarMainGrid}>
            <Grid item xs={5} className={classes.textLeft}>
              <a href={"/about"}>
                <img
                  src={JANGL_LOGO_2X}
                  alt="company logo"
                  srcSet={`${JANGL_LOGO_2X} 1x, ${JANGL_LOGO_3X} 2x`}
                  className={classes.logo}
                />
              </a>
            </Grid>
            <Grid item xs={7} className={classes.btnGridContainer}>
              <NavLink
                to="/about"
                className={`${
                  trigger || !withBackground
                    ? classes.darkFontColor
                    : headerValue
                    ? classes.darkFontColor
                    : classes.inactiveFontColor
                } ${classes.btns} ${classes.hideAppBarTextBtn}`}
                activeClassName={classes.appBarTextBtnActive}
              >
                {t("ABOUT")}
              </NavLink>
              <NavLink
                to="/solutions/"
                className={`${
                  trigger || !withBackground
                    ? classes.darkFontColor
                    : headerValue
                    ? classes.darkFontColor
                    : classes.inactiveFontColor
                } ${classes.btns} ${classes.darkFontColor} ${
                  classes.hideAppBarTextBtn
                }`}
                activeClassName={classes.appBarTextBtnActive}
              >
                {t("SOLUTIONS")}
              </NavLink>
              <NavLink
                to="/platform/"
                className={`${
                  trigger || !withBackground ? classes.darkFontColor : classes.inactiveFontColor
                } ${classes.btns} ${classes.hideAppBarTextBtn} ${
                  headerValue && classes.appBarTextBtnActive
                }`}
                isActive={(match, location) =>
                  location.pathname == "/platform/"
                    ? setHeaderValue(1)
                    : setHeaderValue(0)
                }
              >
                {t("PLATFORM")}
              </NavLink>
              <Link
                to="#request-demo"
                component={RequestDemoLink}
                className={`${classes.btns} ${classes.btnDemo}`}
              >
                {t("REGISTER_DEMO")}
              </Link>
            <div className={classes.toggleBtn}>
                <IconButton onClick={handleMenu}>
                  <img
                    src={MENUICON}
                    alt=""
                    className={classes.menuIconStyling}
                  />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <NavLink
                    to="/about"
                    className={`${classes.darkFontColor}`}
                    activeClassName={classes.primaryFontColor}
                  >
                    <MenuItem onClick={handleClose}>About</MenuItem>
                  </NavLink>
                  <NavLink
                    to="/solutions"
                    className={`${classes.darkFontColor}`}
                    activeClassName={classes.primaryFontColor}
                  >
                    <MenuItem onClick={handleClose}>Solution</MenuItem>
                  </NavLink>
                  <NavLink
                    to="/platform"
                    className={`${classes.darkFontColor}`}
                    activeClassName={classes.primaryFontColor}
                  >
                    <MenuItem onClick={handleClose}>Platform</MenuItem>
                  </NavLink>
                </Menu>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
