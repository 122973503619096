import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  listTitle: {
    fontWeight: theme.typography.weights.semiBold,
    textAlign: 'left'
  },
  bulletList: {
    margin: '0',
    padding: '0',
    listStyle: 'none',
    columns: 'var(--list-columns-sm)',

    [theme.breakpoints.up('sm')]: {
      columns: 'var(--list-columns-lg)'
    }
  },
  bulletListItem: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-0.625rem'
  },
  bulletListIcon: {
    marginRight: '0.5rem',
    height: '2.5rem',
    width: '2.5rem'
  }
}));
