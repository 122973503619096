import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import {
  ImageText,
  RequestDemo,
  HeroComponent,
  FixedAspectRatioImage
} from "../../components";
import useHeroStyles from "../../components/Hero/hero.styles";
import useImgTextStyles from "../../components/ImageText/ImageText.styles";
import Header from "../Header/Header.component";
import ABOUT_CHOOSE_LEADS_IMG from "../../assets/about__choose_leads.png";
import ABOUT_JANGL_PLATFORM_IMG from "../../assets/about__jangl_platform.png";
import useCommonStyles from "../../commonStyles";
import useAboutStyles from "./About.style";
export default function About(props) {
  const { t } = useTranslation();
  const classes = useHeroStyles();
  const imgTextClasses = useImgTextStyles();
  const commonClasses = useCommonStyles();
  const pageClasses = useAboutStyles();
  return (
    <div>
      <div className={classes.heroBackgroundImage}>
        <Header />
        <HeroComponent
          title={t("HERO_TITLE_PAGE_1")}
          content={t("HERO_CONTENT_PAGE_1")}
          buttonLabel={t("LEARN_MORE")}
          titleClass={classes.aboutHeroTitle}
          paraClass={classes.aboutHeroPara}
          textWrapper={classes.aboutTextWrapper}
        />
      </div>
      <Container className={commonClasses.tempRootGrid}>
        <div
          className={`${commonClasses.featureBlocksWrapper} ${commonClasses.decorPlusIconsAbout}`}
        >
          <ImageText
            title={t("JANGL_PLATFORM_TITLE")}
            descriptionChild={<p>{t("JANGL_PLATFORM_CONTENT")}</p>}
            fadeDecorAboutClass={`${pageClasses.fadeDecorAbout} ${pageClasses.fadeDecorAboutPosition1}`}
            mediaChild={
              <FixedAspectRatioImage
                src={ABOUT_JANGL_PLATFORM_IMG}
                ratioWidth={8.5}
                ratioHeight={10}
                imgClass={imgTextClasses.aboutFeatureImg}
                boxClass={`${imgTextClasses.cornerDecorSquare} ${imgTextClasses.cornerDecorSquareBottomLeft} ${commonClasses.featureBlocksMediaBox}`}
              />
            }
          />
          <ImageText
            title={t("CHOOSE_LEADS_TITLE")}
            className={imgTextClasses.featureBlockRightMedia}
            fadeDecorAboutClass={`${pageClasses.fadeDecorAbout} ${pageClasses.fadeDecorAboutPosition2}`}
            descriptionChild={
              <React.Fragment>
                <p>{t("CHOOSE_LEADS_CONTENT_PARA_1")}</p>
                <p>{t("CHOOSE_LEADS_CONTENT_PARA_2")}</p>
              </React.Fragment>
            }
            mediaChild={
              <FixedAspectRatioImage
                src={ABOUT_CHOOSE_LEADS_IMG}
                ratioWidth={8.5}
                ratioHeight={10}
                imgClass={imgTextClasses.aboutFeatureImg}
                boxClass={`${imgTextClasses.cornerDecorSquare} ${imgTextClasses.cornerDecorSquareBottomRight} ${commonClasses.featureBlocksMediaBox}`}
              />
            }
          />
        </div>
      </Container>
      <RequestDemo />
    </div>
  );
}
