import React from "react";
import "./App.css";

import { BrowserRouter } from "react-router-dom";
import themeDefault from "./CustomTheme.js";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Loading from "./containers/Backdrop/BackDrop";
import { SnackbarProvider } from "notistack";
import { Slide } from "@material-ui/core";
import CustomRoutes from "./containers/CustomRoutes/CustomRoutes";
import { loaderOperations } from "./store/loader";
import { useDispatch } from "react-redux";
import Footer
 from "./components/UI/Footer/Footer";
function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const routes = () => {
    return (
      <BrowserRouter>
        <CustomRoutes />
        <Loading />
      </BrowserRouter>
    );
  };

  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  const showHideLoader = (param) => {
    if (param) {
      dispatch(loaderOperations.uiStartLoading());
    } else {
      dispatch(loaderOperations.uiStopLoading());
    }
  };

  return (
    <MuiThemeProvider theme={themeDefault}>
      <SnackbarProvider
        preventDuplicate
        maxSnack={10}
        TransitionComponent={TransitionUp}
        className="customAlert"
      >
        <div className="App">
          <div className="content">
            <div  
            className="routes-container">{routes()}</div>
          </div>
          <div >
          <Footer />
          </div>
        </div>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default App;
