import { makeStyles } from "@material-ui/core/styles";
import REQUEST_DEMO_BG from "../../assets/mobile/RequestDemoBg@2x.png";
import REQUEST_DEMO_WEB_BG from "../../assets/web/Group@2x.png";
import PLUS_ICON_REQUEST_DEMO from "../../assets/web/Plus_icon@2x.png";

export default makeStyles((theme) => ({
  requestDemoWrapper: {
    padding: "5rem 3rem",
    display: "flex",
    justifyContent: "center",
    background: `url(${REQUEST_DEMO_WEB_BG}) 100% 100% no-repeat`,
    backgroundSize: "cover",
    margin: "0 auto 10rem",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "140px",
      height: "140px",
      background: `url(${PLUS_ICON_REQUEST_DEMO}) no-repeat`,
      backgroundSize: "80%",
      bottom: -65,
      right: -65,
      backgroundPosition: "right top",
      [theme.breakpoints.between("xs", "sm")]: {
        display: "none"
      }
    },
    [theme.breakpoints.up("sm")]: {
      width: "81%",
      borderRadius: "7.5rem 1.25rem 1.25rem 1.25rem"
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginBottom: "6rem",
      background: `url(${REQUEST_DEMO_BG}) 100% 100% no-repeat`,
      backgroundSize: "cover"
    }
  },
  requestDemoDecorationBadge: {
    position: "absolute",
    height: "4.5rem",
    width: "4.5rem",
    borderRadius: "50%",
    background: `linear-gradient(216deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    top: 0,
    right: "2rem",
    transform: "translate(0, -50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.up("sm")]: {
      height: "5.5rem",
      width: "5.5rem",
      right: 0,
      transform: "translate(25%, -25%)"
    }
  },
  requestDemoDecorationIcon: {
    width: "2.5rem",
    height: "auto",

    [theme.breakpoints.up("sm")]: {
      width: "3rem"
    }
  },
  requestDemoContent: {
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      width: "80%"
    },

    [theme.breakpoints.up("md")]: {
      width: "70%"
    }
  },
  requestDemoTitle: {
    color: theme.palette.neutral["700"],
    fontSize: "1.5rem",
    fontWeight: theme.typography.weights.semiBold,
    lineHeight: theme.typography.lineHeight.title,
    marginBottom: "0.25rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem"
    }
  },
  requestDemoBodyCopy: {
    color: theme.palette.neutral["700"],
    lineHeight: theme.typography.lineHeight.body,
    marginBottom: "3rem",
    fontWeight: theme.typography.weights.medium,

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.375rem"
    }
  },
  requestDemoForm: {
    display: "grid",
    gridGap: "2rem",

    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "1fr auto"
    }
  },
  requestDemoEmailInput: {
    borderRadius: "0.625rem",
    backgroundColor: theme.palette.neutral["100"],
    border: "2px solid transparent",
    fontSize: "1rem",

    "&:focus-within": {
      borderColor: theme.palette.primary.main
    },

    "& fieldset": {
      border: 0
    },

    "& img": {
      width: "1.5rem",
      height: "auto",
      marginRight: "0.5rem"
    },

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.125rem"
    }
  },
  requestDemoBtn: {
    lineHeight: theme.typography.lineHeight.button,
    textTransform: "unset",
    padding: "1rem 3rem",
    color: theme.palette.neutral["100"],
    borderRadius: "0.625rem",
    position: "relative",
    isolation: "isolate",

    "&::before": {
      content: '""',
      position: "absolute",
      top: "0.75rem",
      height: "100%",
      width: "50%",
      backgroundColor: theme.palette.primary.main,
      opacity: "0.75",
      filter: "blur(2.25rem)",
      zIndex: "-1"
    },

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.375rem"
    }
  },
  actionBtn: { color: theme.palette.neutral["100"] }
}));
