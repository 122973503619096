import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  ImageText,
  RequestDemo,
  HeroComponent,
  FixedAspectRatioImage,
  BulletList
} from "../../components";
import useStyles from "./Platform.style";
import useCommonStyles from "../../commonStyles";

import useHeroStyles from "../../components/Hero/hero.styles";
import useImgTextStyles from "../../components/ImageText/ImageText.styles";
import Header from "../Header/Header.component";

import PlatformImage1 from "../../assets/platform-screen1.jpg";
import PlatformImage2 from "../../assets/platform-screen2.jpg";

export default function Platform(props) {
  const { t } = useTranslation();
  const classes = useHeroStyles();
  const imgTextClasses = useImgTextStyles();
  const pageClasses = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <div>
      <div className={classes.heroBdackgroundImage}>
        <Header />
        <HeroComponent
          title={t("PLATFORM_PAGE.HERO_TITLE")}
          containerClass={classes.platformHeroContainer}
          titleClass={classes.platformHeroTitle}
          hideScrollClass={classes.hideScrollIndicator}
        />
      </div>
      <Container className={classes.tempRootGrid}>
        <Typography className={pageClasses.title}>
          {t("PLATFORM_PAGE.TITLE")}
        </Typography>
        <div className={commonClasses.featureBlocksWrapper}>
          <ImageText
            title={t("PLATFORM_PAGE.CAMPAIGN_SETUP_TITLE")}
            descriptionChild={
              <React.Fragment>
                <p>{t("PLATFORM_PAGE.CAMPAIGN_SETUP_PARA")}</p>
                <BulletList
                  listTitle={t("PLATFORM_PAGE.CAMPAIGN_SETUP_BULLETS_TITLE")}
                  listItems={[
                    t("PLATFORM_PAGE.CAMPAIGN_SETUP_BULLET_1"),
                    t("PLATFORM_PAGE.CAMPAIGN_SETUP_BULLET_2"),
                    t("PLATFORM_PAGE.CAMPAIGN_SETUP_BULLET_3"),
                    t("PLATFORM_PAGE.CAMPAIGN_SETUP_BULLET_4")
                  ]}
                  columns={{ lg: 2 }}
                />
              </React.Fragment>
            }
            mediaCaption={t("PLATFORM_PAGE.CAMPAIGN_SETUP")}
            mediaChild={
              <FixedAspectRatioImage
                src={PlatformImage1}
                ratioWidth={8}
                ratioHeight={5}
                imgClass={imgTextClasses.aboutFeatureImg}
                boxClass={`${imgTextClasses.cornerDecorSquare} ${imgTextClasses.cornerDecorSquareTopLeft} ${commonClasses.featureBlocksMediaBox}`}
              />
            }
          />
          <ImageText
            title={t("PLATFORM_PAGE.POWERFUL_TOOLS_TITLE")}
            className={imgTextClasses.featureBlockRightMedia}
            descriptionChild={
              <React.Fragment>
                <p>{t("PLATFORM_PAGE.POWERFUL_TOOLS_PARA")}</p>
                <BulletList
                  listTitle={t("PLATFORM_PAGE.POWERFUL_TOOLS_BULLETS_TITLE")}
                  listItems={[
                    t("PLATFORM_PAGE.POWERFUL_TOOLS_BULLET_1"),
                    t("PLATFORM_PAGE.POWERFUL_TOOLS_BULLET_2"),
                    t("PLATFORM_PAGE.POWERFUL_TOOLS_BULLET_3"),
                    t("PLATFORM_PAGE.POWERFUL_TOOLS_BULLET_4")
                  ]}
                  columns={{ lg: 2 }}
                />
              </React.Fragment>
            }
            mediaCaption={t("PLATFORM_PAGE.EXECUTION")}
            mediaChild={
              <FixedAspectRatioImage
                src={PlatformImage2}
                ratioWidth={8}
                ratioHeight={5}
                imgClass={imgTextClasses.aboutFeatureImg}
                boxClass={`${imgTextClasses.cornerDecorSquare} ${imgTextClasses.cornerDecorSquareTopRightAltered} ${commonClasses.featureBlocksMediaBox}`}
              />
            }
          />
          <ImageText
            title={t("PLATFORM_PAGE.REPORTING_TITLE")}
            descriptionChild={
              <React.Fragment>
                <p>{t("PLATFORM_PAGE.REPORTING_PARA")}</p>
                <BulletList
                  listTitle={t("PLATFORM_PAGE.REPORTING_BULLETS_TITLE")}
                  listItems={[
                    t("PLATFORM_PAGE.REPORTING_BULLET_1"),
                    t("PLATFORM_PAGE.REPORTING_BULLET_2"),
                    t("PLATFORM_PAGE.REPORTING_BULLET_3"),
                    t("PLATFORM_PAGE.REPORTING_BULLET_4")
                  ]}
                  columns={{ lg: 2 }}
                />
              </React.Fragment>
            }
            mediaCaption={t("PLATFORM_PAGE.REPORTING")}
            mediaChild={
              <FixedAspectRatioImage
                src={PlatformImage2}
                ratioWidth={8}
                ratioHeight={5}
                imgClass={imgTextClasses.aboutFeatureImg}
                boxClass={`${imgTextClasses.cornerDecorSquare} ${imgTextClasses.cornerDecorSquareTopLeft} ${commonClasses.featureBlocksMediaBox}`}
              />
            }
          />
        </div>
      </Container>
      <RequestDemo />
    </div>
  );
}
