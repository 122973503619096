import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import Header from "../../containers/Header/Header.component";
import useStyles from "./TermsOfUse.styles";

export function TermsOfUse() {
  const classes = useStyles();
  const { t } = useTranslation();
  const section6Ref = useRef();

  function scrollToSection6(ev) {
    ev.preventDefault();
    section6Ref.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div>
    <Header withBackground={false}/>
    <div className={classes.termsWrapper}>
      <section>
        <Typography variant="h2" className={classes.termsHeading}>
          {t("TERMS_OF_USE.PAGE_HEADING")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.MAIN_PARA_1")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.MAIN_PARA_2")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.MAIN_PARA_3")}
        </Typography>
        <Typography className={`${classes.termsBody} ${classes.inlineBlockElem}`}>
          {t("TERMS_OF_USE.MAIN_PARA_4")}
        </Typography>
        &nbsp;
        <Link
          to="/legal/privacy"
          className={`${classes.termsBody} ${classes.termsLink} ${classes.inlineBlockElem}`}
        >
          {t("PRIVACY_POLICY.PAGE_HEADING")}
        </Link>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.DESCRIPTION_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.DESCRIPTION_PARA_1")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.DESCRIPTION_PARA_2")}
        </Typography>
        <dl className={classes.termsIndentedDl}>
          <dt className={`${classes.termsDlDt} ${classes.termsBody}`}>
            {t("TERMS_OF_USE.DESCRIPTION_VISITORS")}
          </dt>
          <dd className={classes.termsBody}>
            {t("TERMS_OF_USE.DESCRIPTION_VISITORS_BODY")}
          </dd>
          <dt className={`${classes.termsDlDt} ${classes.termsBody}`}>
            {t("TERMS_OF_USE.DESCRIPTION_USERS")}
          </dt>
          <dd className={classes.termsBody}>
            {t("TERMS_OF_USE.DESCRIPTION_USERS_BODY")}
          </dd>
        </dl>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.DESCRIPTION_PARA_3")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.RESTRICTIONS_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.RESTRICTIONS_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.ORDERS_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.ORDERS_PARA_1")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.ORDERS_PARA_2")}
        </Typography>
        <ul className={classes.termsUl}>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.ORDERS_BULLET_1")}
          </li>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.ORDERS_BULLET_2")}
          </li>
        </ul>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.PAYMENT_TITLE")}
        </Typography>
        <Typography className={`${classes.termsBody} ${classes.inlineElem}`}>
          {t("TERMS_OF_USE.PAYMENT_PARA_PART_1")}
        </Typography>
        <Link
          to="#"
          onClick={scrollToSection6}
          className={`${classes.termsBody} ${classes.termsLink} ${classes.inlineElem}`}
        >
          {t("TERMS_OF_USE.SECTION_6")}
        </Link>
        <Typography className={`${classes.termsBody} ${classes.inlineElem}`}>
          {t("TERMS_OF_USE.PAYMENT_PARA_PART_2")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={`${classes.termsTitle} ${classes.topMargin}`}>
          {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_PARA_1")}
        </Typography>
        <ul className={classes.termsUl}>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_BULLET_1")}
          </li>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_BULLET_2")}
          </li>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_BULLET_3")}
          </li>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_BULLET_4")}
          </li>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_BULLET_5")}
          </li>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_BULLET_6")}
          </li>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_BULLET_7")}
          </li>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_BULLET_8")}
          </li>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_BULLET_9")}
          </li>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_BULLET_10")}
          </li>
          <li className={`${classes.termsBody} ${classes.termsLi}`}>
            {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_BULLET_11")}
          </li>
        </ul>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.COMMUNITY_GUIDELINES_PARA_2")}
        </Typography>
      </section>

      <section ref={section6Ref}>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.SIGN_IN_NAME_PASSWORD_UNIQUE_IDENTIFIERS_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.SIGN_IN_NAME_PASSWORD_UNIQUE_IDENTIFIERS_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.INTELLECTUAL_PROPERTY_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.INTELLECTUAL_PROPERTY_PARA_1")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.INTELLECTUAL_PROPERTY_PARA_2")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.INTELLECTUAL_PROPERTY_PARA_3")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.INTELLECTUAL_PROPERTY_PARA_4")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.COMMUNICATIONS_TO_US_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.COMMUNICATIONS_TO_US_PARA_1")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.COMMUNICATIONS_TO_US_PARA_2")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.COMMUNICATIONS_TO_US_PARA_3")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.COMMUNICATIONS_TO_US_PARA_4")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.USER_DATA_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.USER_DATA_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.LICENSE_TO_LEADS_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.LICENSE_TO_LEADS_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.NO_WARRANTIES_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.NO_WARRANTIES_PARA_1")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.NO_WARRANTIES_PARA_2")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.NO_WARRANTIES_PARA_3")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.NO_WARRANTIES_PARA_4")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.NO_WARRANTIES_PARA_5")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.EXTERNAL_SITES_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.EXTERNAL_SITES_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.INDEMNIFICATION_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.INDEMNIFICATION_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.COMPLIANCE_WITH_APPLICABLE_LAWS_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.COMPLIANCE_WITH_APPLICABLE_LAWS_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.TERMINATION_OF_THE_AGREEMENT_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.TERMINATION_OF_THE_AGREEMENT_PARA")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.DIGITAL_MILLENNIUM_COPYRIGHT_ACT_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.DIGITAL_MILLENNIUM_COPYRIGHT_ACT_PARA_1")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.DIGITAL_MILLENNIUM_COPYRIGHT_ACT_PARA_2")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.DIGITAL_MILLENNIUM_COPYRIGHT_ACT_PARA_3")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.DIGITAL_MILLENNIUM_COPYRIGHT_ACT_PARA_4")}
        </Typography>
      </section>

      <section>
        <Typography variant="h3" className={classes.termsTitle}>
          {t("TERMS_OF_USE.MISCELLANEOUS_TITLE")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.MISCELLANEOUS_PARA_1")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.MISCELLANEOUS_PARA_2")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.MISCELLANEOUS_PARA_3")}
        </Typography>
        <Typography className={classes.termsBody}>
          {t("TERMS_OF_USE.MISCELLANEOUS_PARA_4")}
        </Typography>
      </section>
    </div>
    </div>
  );
}
