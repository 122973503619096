import { makeStyles } from "@material-ui/core/styles";
import PLATFORM_PAGE_DECORE from "./assets/platform_page_decor@2.png";

export default makeStyles((theme) => ({
  rootContainer: {
    maxWidth: "1170px",
    minWidth: "960px",
    padding: "0",
    marginRight: "auto",
    marginLeft: "auto",
    minHeight: "700px",
    mozBoxShadow: "0 3px 3px rgba(0, 0, 0, 0.3)",
    webkitBoxShadow: "0 3px 3px rgba(0, 0, 0, 0.3)",
    boxShadow: "0 3px 3px rgba(0, 0, 0, 0.3)",
    zIndex: "50",
    paddingTop: "40px"
  },
  tempRootGrid: {
    display: "grid",
    gridGap: "8rem",
    marginTop: "2rem"
  },

  featureBlocksWrapper: {
    "& > *": {
      marginBottom: "10rem",
      [theme.breakpoints.between("xs", "sm")]: {
        marginTop: "4rem",
        marginBottom: "4rem"
      }
    }
  },
  featureBlocksMediaBox: {
    width: "70%",
    margin: "0 auto",

    [theme.breakpoints.up("md")]: {
      width: "100%"
    }
  },

  decorPlusIconsSolutions: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "146px",
      height: "200px",
      display: "block",
      background: `url(${PLATFORM_PAGE_DECORE}) no-repeat`,
      backgroundSize: "contain",
      top: "0",
      right: 30,
      zIndex: -1,
      transform: "translate(10%, -30%)",
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100px",
        height: "130px",
        backgroundSize: "160%",
        backgroundPosition: "0 80%",
        top: 10,
        right: 0,
        zIndex: -1
      }
    }
  },
  decorPlusIconsAbout: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "146px",
      height: "200px",
      display: "block",
      background: `url(${PLATFORM_PAGE_DECORE}) no-repeat`,
      backgroundSize: "contain",
      top: 60,
      right: 30,
      zIndex: -1,
      transform: "translate(10%, -30%)",
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100px",
        height: "130px",
        backgroundSize: "160%",
        backgroundPosition: "0 80%",
        top: 40,
        right: "8vw"
      }
    }
  }
}));
